import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { PRODUCTAdd, PRODUCTGet, PRODUCTUpdate,SetPRODUCTObj } from "../../../redux/actions/Product/Product.actions";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

function GeneralProduct() {
  const dispatch = useDispatch();
  const [productName, setProductName] = useState("");
  const [sku, setSku] = useState("");
  const [price, setPrice] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [ptype, setPtype] = useState("product");
  const [tags, setTags] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [description, setDescription] = useState("");
  // const [relatedProduct, setRelatedProduct] = useState([]);
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");

  const [globalProductsArr, setGlobalProductsArr] = useState([]);

  const products = useSelector((state) => state.product.products);
  const productObj = useSelector((state) => state.product.productObj);

  console.log(products , "products");

  useEffect(() => {
    dispatch(PRODUCTGet());
    // setGlobalProductsArr(products);
  }, []);

 

  const handleFileSet = (value, index) => {
    let tempArr = imageArr;

    tempArr[index].image = value
    setImageArr([...tempArr]);
  };

  const handleFileUpload = (value) => {
    setMetaImage(value)
  }

  const handleSubmit = () => {
    if (productName == "") {
      toastError("Name is mandatory")
      return
    }
    else if (price == 0) {
      toastError("Price is mandatory")
      return
    }
    else if (sku == 0) {
      toastError("Sku is mandatory")
      return
    }

    let obj = {
      productName,
      sku,
      price: parseInt(price),
      mrp: parseInt(mrp),
      tags,
      additionalInfo,
      description,
      ptype,
      // relatedProduct : products.map(el => ({ productId: el._id })),
      imageArr,
      metaTitle,
      metaDescription,
      metaImage,
    };

    if (productObj) {
      dispatch(PRODUCTUpdate(obj, productObj._id));
      dispatch(SetPRODUCTObj());
    }
    else {
      dispatch(PRODUCTAdd(obj));
    }
  };

  useEffect(()=>{
    if(productObj){
      setProductName(productObj?.productName);
      setSku(productObj?.sku);
      setPrice(productObj?.price);
      setMrp(productObj?.mrp);
      setTags(productObj?.tags);
      setAdditionalInfo(productObj?.additionalInfo);
      setDescription(productObj?.description);
      setPtype(productObj?.ptype);
      // setRelatedProduct(productObj?.relatedProduct);
      setImageArr(productObj?.imageArr);
      setMetaTitle(productObj?.metaTitle);
      setMetaDescription(productObj?.metaDescription);
      setMetaImage(productObj?.metaImage);

      // console.log(productObj?.relatedProduct , "releted releted related");
    }
  },[productObj])

  const handleImageObjAdd = () => {
    let tempArr = imageArr
    tempArr.push({ image: "", imageAlt: "" })
    console.log(tempArr, "asdas")
    setImageArr([...tempArr])
  }
  const handleImageObjRemove = () => {
    if (imageArr.length > 1) {
      let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
      setImageArr([...tempArr])
    }
  }
 
  const handleproductImageAltEntry = (value, index) => {
    console.log(value, index)
    let tempArr = imageArr;
    tempArr[index].imageAlt = value
    setImageArr([...tempArr]);
  };

  return (
    <form className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input value={productName} onChange={(event) => setProductName(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label> PRODUCT SKU <span className="red">*</span></label>
                <input value={sku} onChange={(event) => setSku(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label> Product Type <span className="red">*</span></label>
                <select className='form-control' value={ptype} onChange={(event) => setPtype(event.target.value)} >
                  <option value="product">Product</option>
                  <option value="set-product">Set Product</option>
                </select>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label> PRICE <span className="red">*</span></label>
                <input value={price} onChange={(event) => setPrice(event.target.value)} type="text"  className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label> MRP <span className="red">*</span></label>
                <input value={mrp} onChange={(event) => setMrp(event.target.value)} type="text"  className="form-control" />
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label>
                  PRODUCT TAGS <span className="red">(Separete by comma)</span>
                </label>
                <input type="text" className="form-control" value={tags} onChange={(e)=>{setTags(e.target.value)}} />
              </div>
              
            </div>
            
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Additional Information </h5>
              <div className="col-12 mb-3">
                <ReactQuill value={additionalInfo != "" && additionalInfo ? additionalInfo : ""} theme="snow" onChange={(e) => setAdditionalInfo(e)} />
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Description </h5>
              <div className="col-12 mb-3">
                <ReactQuill value={description != "" && description ? description : ""} theme="snow" onChange={(e) => setDescription(e)} />
              </div>
            </div>
            
            <div className="row">
              <h5 className="blue-1 mb-4">SEO info</h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>

                {
                  metaImage != "" &&
                  <>
                    <br />
                    <br />
                    <img src={`${metaImage}`.includes("base64") ? metaImage : generateFilePath(metaImage)} style={{ height: 80 }} />
                    <br />
                    <br />
                  </>
                }

                <FileUpload onFileChange={handleFileUpload} />
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="col-12 mb-3">
              <div className="row d-flex">
                <h5 className="blue-1 mb-3">Product Image Info</h5>
                <div className="row">
                  <div className="col-2 me-5">
                    <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                  </div>
                  <div className="col-2">
                    <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                  </div>
                </div>
              </div>
              {imageArr && imageArr.length > 0 && imageArr.map((el, index) => {
                return (
                  <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                    <div className="col-12">
                      <label>
                        Product Image<span className="red">*</span>
                      </label>
                      {
                        el.image != "" &&
                        <>
                          <br />
                          <br />
                          <img src={`${el.image}`.includes("base64") ? el.image : generateFilePath(el.image)} style={{ height: 80 }} />
                          <br />
                          <br />
                        </>
                      }

                      <FileUpload onFileChange={(val) => handleFileSet(val, index)} />
                    </div>
                    <div className="col-12">
                      <label>
                        Product Image alt<span className="red">*</span>
                      </label>
                      <input onChange={(e) => handleproductImageAltEntry(e.target.value, index)} value={el?.imageAlt} type="text" className="form-control" />
                    </div>
                  </div>
                )
              })}
            </div>
          </DashboardBox>
        </div>
      </div >
    </form >
  );
}

export default GeneralProduct;
