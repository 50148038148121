import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";  
import { useDispatch, useSelector } from "react-redux";
import {
  CONTACTAdd,
  CONTACTUpdate,
  SetCONTACTObj,
} from "../../../redux/actions/Contact/Contact.actions";
// import QuillEditor from "../../../utils/QuillEditor";
// import { toastError } from "../../Utility/ToastUtils";
// import { generateFilePath } from "../../Utility/utils";

function ViewContact() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [city, setCity] = useState("");
  // const [street, setStreet] = useState("");
  // const [pincode, setPincode] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const bannerObj = useSelector((state) => state.contact.contactObj);

  useEffect(() => {
    if (bannerObj) {
      // setselectedBannerId(bannerObj._id);
      setName(bannerObj.name);
      setEmail(bannerObj.email);
      // setPhone(bannerObj.phone);
      // setCity(bannerObj.city);
      // setStreet(bannerObj.street);
      // setPincode(bannerObj.pincode);
      setMessage(bannerObj.message);
      console.log(bannerObj);
      // setSelectedStatus({ value: brandObj.statusInfo, label: brandObj.statusInfo });
      // setIsUpdateBanner(true);
    }
    return () => {
      dispatch(SetCONTACTObj(null));
    };
  }, [bannerObj]);

  
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          {/* <h5 className="blue-1 mb-4">
            {isUpdateBanner ? "Update" : "Add New "} Branner
          </h5> */}
          <form action="#" className="form">
            <div className="row gy-4 gy-xxl-0">
              <div className="col-12 col-xxl-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Contact Information</h5>
                    <div className="col-12">
                      <label><b>Name</b> : <span className="">{name} </span></label>
                    </div>
                    <div className="col-12">
                      <label><b>Email</b>  :  {email}</label>
                    </div>
                    {/* <div className="col-12">
                      <label><b>Phone</b>  :  {phone}</label>
                    </div>
                    <div className="col-12">
                      <label> <b>Street</b>  :  {street}</label>
                    </div>
                    <div className="col-12">
                      <label><b>Pincode</b>  :  {pincode}</label>
                    </div> */}
                    <div className="col-12">
                      <label><b>Message</b>   :  {message}</label>
                    </div>                   
                  </div>
                </DashboardBox>
              </div>
              
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default ViewContact;
