export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
};

export const ORDER_STATUS = {
  PENDING: "PENDING",
  CONFIRMED:"CONFIRMED",
  ORDERED: "ORDERED",
  PROCESSED: "PROCESSED",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  R: "R",
};