import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
// import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { CONTACTGet, SetCONTACTObj ,CONTACTDelete} from "../../../redux/actions/Contact/Contact.actions";
// import { generateFilePath } from "../../Utility/utils";

function Contact() {
  const dispatch = useDispatch();

  const bannerArr = useSelector((state) => state.contact.contacts);

  useEffect(() => {
    dispatch(CONTACTGet());
  }, []);

  const handleEdit = (row) => {
    console.log(row , "row row row");
    dispatch(SetCONTACTObj(row));
  };

  const handleBlogDelete = (row) => {
    let confirm =window.confirm("Do you really want to delete this item?")
    if (confirm) {
      dispatch(CONTACTDelete(row));
    }

  };
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "20%",
    },
    {
      name: "Email",
      grow: 0,
      selector: (row) => row.email,
      width: "20%",
    },
    {
      name: "Message",
      button: true,
      selector: (row) => row.message,
      width: "20%",
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Contact/Contact-Create" onDeleteClick={() => handleBlogDelete(row?._id)} deletePath="/Contacts" remove edit Uniquekey={row.id} />,
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Contact List</h5>
                <div className="d-flex align-items-center gap-3">
                  {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW BANNER" path="/Banners/Banner-Create" small roundedPill /> */}
                  <SearchBox extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={bannerArr && bannerArr.length > 0 ? bannerArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Contact;
