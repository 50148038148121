import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/contact";

export const addContact = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addContact", formData);
};

export const getContact = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getContact?${query}`);
};

export const deleteContact = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateContact = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
