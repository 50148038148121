import {
    addContact,
    deleteContact,
    getContact,
    updateContact,
  } from "../../../services/contact.service";
  
  export const CONTACT_ADD = "CONTACT_ADD";
  export const CONTACT_ADD_SUCCESS = "CONTACT_ADD_SUCCESS";
  export const CONTACT_ADD_FAIL = "CONTACT_ADD_FAIL";
  
  export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
  export const GET_ALL_CONTACTS_SUCCESS = "GET_ALL_CONTACTS_SUCCESS";
  export const GET_ALL_CONTACTS_FAIL = "GET_ALL_CONTACTS_FAIL";
  
  export const UPDATE_CONTACT_BY_ID = "UPDATE_CONTACT_BY_ID";
  export const UPDATE_CONTACT_BY_ID_SUCCESS = "UPDATE_CONTACT_BY_ID_SUCCESS";
  export const UPDATE_CONTACT_BY_ID_FAIL = "UPDATE_CONTACT_BY_ID_FAIL";
  
  export const SET_CONTACT_OBJ = "SET_CONTACT_OBJ";
  export const SET_CONTACT_OBJ_SUCCESS = "SET_CONTACT_OBJ_SUCCESS";
  export const SET_CONTACT_OBJ_FAIL = "SET_CONTACT_OBJ_FAIL";
  
  export const GET_CONTACT_BY_ID = "GET_CONTACT_BY_ID";
  export const GET_CONTACT_BY_ID_SUCCESS = "GET_CONTACT_BY_ID_SUCCESS";
  export const GET_CONTACT_BY_ID_FAIL = "GET_CONTACT_BY_ID_FAIL";
  
  export const DELETE_CONTACT_BY_ID = "DELETE_CONTACT_BY_ID";
  export const DELETE_CONTACT_BY_ID_SUCCESS = "DELETE_CONTACT_BY_ID_SUCCESS";
  export const DELETE_CONTACT_BY_ID_FAIL = "DELETE_CONTACT_BY_ID_FAIL";
  
  export const CONTACTAdd = (formData) => async (dispatch) => {
    try {
      dispatch({ type: CONTACT_ADD });
      let { data: response } = await addContact(formData);
      if (response) {
        console.log(response);
        dispatch({
          type: CONTACT_ADD_SUCCESS,
          payload: response.message,
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: CONTACT_ADD_FAIL, payload: err });
    }
  };
  
  export const CONTACTGet = (formData) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_CONTACTS });
      let { data: response } = await getContact(formData);
      if (response) {
        console.log(response);
        dispatch({
          type: GET_ALL_CONTACTS_SUCCESS,
          payload: { data: response.data, message: response.message },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: CONTACT_ADD_FAIL, payload: err });
    }
  };
  
  export const SetCONTACTObj = (formData) => async (dispatch) => {
    try {
      dispatch({ type: SET_CONTACT_OBJ });
      if (formData) {
        dispatch({
          type: SET_CONTACT_OBJ_SUCCESS,
          payload: { data: formData },
        });
      } else {
        dispatch({
          type: SET_CONTACT_OBJ_SUCCESS,
          payload: { data: null },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: SET_CONTACT_OBJ_FAIL, payload: { message: "NOT FOUND" } });
    }
  };
  
  export const CONTACTUpdate = (formData, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CONTACT_BY_ID });
      let { data: response } = await updateContact(formData, id);
      if (response) {
        console.log(response);
        dispatch({
          type: UPDATE_CONTACT_BY_ID_SUCCESS,
          payload: response,
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: UPDATE_CONTACT_BY_ID_FAIL, payload: err });
    }
  };
  
  export const CONTACTDelete = (formData, id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_CONTACT_BY_ID });
      let { data: response } = await deleteContact(formData, id);
      if (response) {
        console.log(response, "response");
        dispatch({
          type: DELETE_CONTACT_BY_ID_SUCCESS,
          payload: response,
        });
        CONTACTGet();
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: DELETE_CONTACT_BY_ID_FAIL, payload: err });
    }
  };
  