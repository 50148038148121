import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { getOrderById, updateOrderById, updateStatusOrderById } from "../../../services/order.service";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import CustomButton from "../../Utility/Button";
import { ORDER_STATUS } from "../../Utility/constants";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { generateFilePath } from "../../Utility/utils";
import { getOrderIdSequence } from "../../../utils/Country";

function SaleDetail(props) {
  // =========================================================================================

  const [searchParams, setSearchParams] = useSearchParams();
  const [trackingId, setTrackingId] = useState("");
  const [orderObj, setOrderObj] = useState({});
  const [selecetedOrderStatus, setSelecetedOrderStatus] = useState({});
  const getOrder = async () => {
    try {
      const { data: res } = await getOrderById(searchParams.get("orderId"));
      if (res) {
        console.log(res.data);
        setOrderObj(res.data);
        setTrackingId(res.data.trackingId);
        setSelecetedOrderStatus({ label: res.data.status, value: res.data.status });
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(orderObj , "orderObj orderObj orderObj orderObj");

  useEffect(() => {
    getOrder();
  }, []);

  const sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: '5%'
    },
    {
      name: "Image",
      selector: (row) => <img src={generateFilePath(row?.image)} alt="" />,
      width: '10%'
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      // selector: (row) => <a href={`https://www.aahilya.com/Product-Detail/${row?.productId?.slug}`} target="_blank">{row?.name}</a>,
      width:'45%'
    },

    {
      name: "Qty",
      cell: (row) => <p className="fs-14">{row.quantity}</p>,
      width: '5%'
    },
    {
      name: "Price",
      selector: (row) => `₹ ${row.price}`,
      width:'10%'
    },

    {
      name: "Total",
      selector: (row) => `₹ ${ row.totalPrice}`,
      width:'10%'
    },
  ];

  const handlePrint = async () => {
    // try {
    //   const { data: res } = await getInvoiceById(orderObj?._id);
    //   if (res) {
    //     window.open(`${url}/${res.data}`);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOrderUpdate = async () => {
    try {
      let updateObj = {
        trackingId,
        status: selecetedOrderStatus?.label,
        userId: orderObj?.userId
      };

      // console.log(updateObj,"updateObjupdateObjupdateObj")
      // console.log(orderObj,"orderObj?._idorderObj?._id get ")
      // console.log(orderObj?.addressObj,"===_id get ")
      const { data: res } = await updateStatusOrderById(orderObj?._id, updateObj);
      if (res) {
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      toastError(error);
    }
  };

  const order_comfirm = [
    { value: ORDER_STATUS.PENDING, label: ORDER_STATUS.PENDING },
    { value: ORDER_STATUS.CONFIRMED, label: ORDER_STATUS.CONFIRMED },
    { value: ORDER_STATUS.PROCESSED, label: ORDER_STATUS.PROCESSED },
    { value: ORDER_STATUS.DISPATCHED, label: ORDER_STATUS.DISPATCHED },
    { value: ORDER_STATUS.DELIVERED, label: ORDER_STATUS.DELIVERED },
    { value: ORDER_STATUS.CANCELLED, label: ORDER_STATUS.CANCELLED },
  ];

  //   =========================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="col-12 col-md-12">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5 className="blue-1 m-0">Order Details</h5>
              <CustomButton ClickEvent={() => handlePrint()} path={`/Order/Sale-Detail?orderId=${orderObj?._id}`} isLink downloadAble btnName="PRINT" noIcon noIconMargin />
            </div>
          </div>
          <div className="col-12 col-md-12">
            <DashboardBox>
              <div className="row">

                {/* <div className="col-md-4">
                  <label>Tracking Details </label>
                  <textarea className="form-control" value={trackingId} onChange={(e) => setTrackingId(e.target.value)} ></textarea>
                </div> */}
                <div className="col-md-4">
                  <label>Order Status </label>
                  <Select options={order_comfirm} onChange={(e) => setSelecetedOrderStatus(e)} value={selecetedOrderStatus} />
                </div>

                <div className="col-md-4 text-center mt-2">
                  <CustomButton isBtn btntype="button" ClickEvent={() => handleOrderUpdate()} iconName="fa-solid fa-check" btnName="Save" />
                </div>

              </div>
            </DashboardBox>
          </div>
          <hr />
          <div className="row">
            <DashboardBox className="col-12 col-md-12 row  m-0">
              <div className="col-12 col-md-6">
                <DashboardBox>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize ">Shipped by</h6>
                    <p>
                      EEKSHA <br />
                      C125, Sector 1, Rohini,<br />
                      New Delhi, Pin- 110085 <br />
                    </p>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-md-6">
                <DashboardBox className="blue-1">
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">EEKSHA</h6>
                    <ul className=" fs-14 text-uppercase">
                      <li>
                        <span className="fw-600">
                          Order Id<span>:</span>
                        </span>
                        {orderObj.orderId ? '#' + getOrderIdSequence(orderObj.orderId) : orderObj._id}
                      </li>
                      <li>
                        <span className="fw-600">
                          Payment Method<span>:</span>
                        </span>
                        {orderObj?.paymentMethod ? orderObj?.paymentMethod : 'Razorpay'}
                      </li>
                      <li>
                        <span className="fw-600">
                          Date Added:<span>:</span>
                        </span>
                        {new Date(orderObj?.createdAt).toDateString()}
                      </li>

                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <hr />
              <div className="col-12 col-md-6">
                <DashboardBox>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize ">Shipped to</h6>
                   <ul className="blue-1 fs-14 text-uppercase">
                      <li>
                        {orderObj?.addressArr?.firstName} , 
                      </li>
                      <li>
                        {orderObj?.addressArr?.state}
                      </li>
                      <li>
                        {orderObj?.addressArr?.street},
                      </li>                   
                      <li>                    
                      {orderObj?.addressArr?.pincode}
                      </li>                     
                      <li>
                      {orderObj?.addressArr?.phone}
                      </li>
                      <li>
                      {orderObj?.addressArr?.email}                      
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-md-6">
                <DashboardBox className="blue-1">
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3"> 	Billing Details</h6>
                    <ul className="blue-1 fs-14 text-uppercase">
                      <li>
                        {orderObj?.addressArr?.firstName} , 
                      </li>
                      <li>
                        {orderObj?.addressArr?.state}
                      </li>
                      <li>
                        {orderObj?.addressArr?.street},
                      </li>                   
                      <li>                    
                      {orderObj?.addressArr?.pincode}
                      </li>                     
                      <li>
                      {orderObj?.addressArr?.phone}
                      </li>
                      <li>
                      {orderObj?.addressArr?.email}                      
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12">
                <DashboardBox className="blue-1">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      {
                        orderObj?.orderNotes && (
                      <h5 className="blue-1 mb-3">Order Notes: {orderObj?.orderNotes}</h5>

                        )
                      }
                      {/* <CustomButton redBtn btnName="Pending" />/ */}

                      Status : {orderObj?.status}
                    </div>
                    {/* <h6 className="border py-2 m-0 px-4 text-white bg-black rounded-2 fs-14">Shipping Method : NA</h6> */}
                  </div>
                  <DashboardTable className="my-4">
                    <DataTable columns={sale_columns} data={orderObj?.items} pagination />
                  </DashboardTable>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Order Info</h6>
                    <ul className="blue-1 fs-14">
                      {/* <li>
                        <span className="fw-600">
                          Is Paid<span>:</span>
                        </span>
                        No
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Subtotal<span>:</span>
                        </span>
                        {orderObj?.subTotalAmount}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          Discount<span>:</span>
                        </span>
                        - {orderObj?.currencyObj?.symbol} 0.00
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Shipping Charge<span>:</span>
                        </span>
                        {orderObj?.shippingCharges}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          TAX/GST<span>:</span>
                        </span>
                        {orderObj?.currencyObj?.symbol} {orderObj?.items?.reduce((acc, el) => acc + el.cgst + el.sgst, 0)}
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Grand Total<span>:</span>
                        </span>
                        {orderObj?.currencyObj?.symbol}{orderObj?.currencyObj?.convertRate ? Math.round(orderObj?.currencyObj?.convertRate*orderObj.totalAmount) : orderObj.totalAmount}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
            </DashboardBox>
            {/* <div className="col-12 col-md-4">
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label>Tracking Id </label>
                    <input type="text" value={trackingId} onChange={(e) => setTrackingId(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-12">
                    <label>Order Status </label>
                    <Select options={order_comfirm} onChange={(e) => setSelecetedOrderStatus(e)} value={selecetedOrderStatus} />
                  </div>

                  <div className="col-12 text-center mt-2">
                    <CustomButton isBtn btntype="button" ClickEvent={() => handleOrderUpdate()} iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </form>
              </DashboardBox>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
}

export default SaleDetail;
